<template>
  <div>
    <div class="row">
      <div v-if="isSlot" class="col-3">
        <slot></slot>
      </div>
      <div class="col-3 mb-3">
        <el-select
          clearable
          @clear="searchProject"
          v-model="country_id"
          @change="searchProject"
          placeholder="Country"
        >
          <el-option
            v-for="(v, i) in countryList"
            :key="i"
            :label="v.name"
            :value="v.id"
          >
          </el-option>
        </el-select>
      </div>

      <!-- 组织 -->
      <div class="col-3">
        <!-- 选择等级 -->
        <el-select
          v-model="level_id"
          @change="getOrganizationList(level_id, '')"
          placeholder="Level"
        >
          <el-option
            v-for="v in levelList"
            :key="v.id"
            :label="v.name"
            :value="v.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 
        { id: 0, name: "JCI" },
        { id: 4, name: "Area" },
        { id: 2, name: "National" },
        { id: 3, name: "Regional" },
        { id: 1, name: "Local" },
       -->
      <!-- JCI -->
      <div class="col-3" v-if="level_id == 0">
        <el-select v-model="country" placeholder="JCI" value-key="id">
          <el-option
            v-for="v in organList"
            :key="v.id"
            :label="v.name"
            :value="v"
          >
          </el-option>
        </el-select>
      </div>
      <!-- Area -->
      <div class="col-3" v-if="level_id == 4">
        <el-select v-model="country" placeholder="Area" value-key="id">
          <el-option
            v-for="v in organList"
            :key="v.id"
            :label="v.name"
            :value="v"
          >
          </el-option>
        </el-select>
      </div>
      <!-- Nation -->
      <div class="col-3" v-if="level_id == 2 || level_id == 3 || level_id == 1">
        <el-select
          v-model="country"
          @change="getSonList(country, 1)"
          placeholder="National"
          value-key="id"
        >
          <el-option
            v-for="v in organList"
            :key="v.id"
            :label="v.name"
            :value="v"
          >
          </el-option>
        </el-select>
      </div>

      <!-- Regional -->
      <div class="col-3" v-if="level_id == 3 || level_id == 1">
        <el-select
          @change="getSonList(region, 2)"
          v-model="region"
          placeholder="Regional"
          value-key="id"
        >
          <el-option
            v-for="v in regionList"
            :key="v.id"
            :label="v.name"
            :value="v"
          >
          </el-option>
        </el-select>
      </div>

      <!-- Local -->
      <div class="col-3" v-if="level_id == 1">
        <el-select v-model="local_id" placeholder="Local" value-key="id">
          <el-option
            v-for="v in localList"
            :key="v.id"
            :label="v.name"
            :value="v.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="col-3 mt-1">
        <button class="btn btn-primary" @click="searchProject">Search</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      country_id: "",
      level_id: "",
      country: {},
      region: {},
      local_id: "",


      countryList: [],
      levelList: [
        { id: 0, name: "JCI" },
        { id: 4, name: "Area" },
        { id: 2, name: "National" },
        { id: 3, name: "Regional" },
        { id: 1, name: "Local" },
      ],
      level_id: "",
      organList: [],
      regionList: [],
      localList: [],
      has_regions: 0

    };
  },

  props: {
    isSlot: {
      type: Boolean,

    }
  },
  mounted () {
    this.getAddress();
  },
  methods: {
    // 搜索
    searchProject () {
      let organazition;
      if (this.level_id == 0 || this.level_id == 4 || this.level_id == 2) {
        organazition = this.country.id;
      } else if (this.level_id == 3) {
        organazition = this.region.id;
      } else if (this.level_id == 1) {
        organazition = this.local_id;
      }
      let params = {
        country_id: this.country_id,
        organization_id: organazition ? organazition : "",
      }

      this.$emit('searchProject', params);
    },

    // 获取国家列表
    async getAddress (level = 2) {
      const params = {
        level
      }
      let { status, data } = await this.$http.getAddress(params)
      if (status == 200) {
        this.countryList = data;
      }
    },
    getSonList (parent, type) {
      // console.log(parent);
      if (type == 1) {
        this.regionList = [];
        this.localList = [];
        this.region = {};
        this.local_id = "";
      } else if (type == 2) {
        this.localList = [];
        this.local_id = "";
      }
      if (
        this.level_id == 3 ||
        (this.level_id == 1 && parent.has_regions == 1)
      ) {
        this.$http.getOrganizationList({ parent_id: parent.id }).then((res) => {
          if (res.status == 200) {
            this.regionList = res.data;
            this.has_regions = parent.has_regions;
          }
        });
      } else if (this.level_id == 1) {
        this.$http.getOrganizationList({ parent_id: parent.id }).then((res) => {
          if (res.status == 200) {
            this.localList = res.data;
            // this.has_regions = res.data && res.data[0].has_region
          }
        });
      }
    },
    // 获取组织列表
    getOrganizationList () {
      this.country = {};
      this.region = {};
      this.organList = [];
      this.regionList = [];
      this.localList = [];
      if (this.level_id == 4 || this.level_id == 2 || this.level_id == 0) {
        this.$http.getOrganizationList({ type: this.level_id }).then((res) => {
          if (res.status == 200) {
            // console.log(res.data);
            this.organList = res.data;
          }
        });
      } else if (this.level_id == 1 || this.level_id == 3) {
        this.$http.getOrganizationList({ type: 2 }).then((res) => {
          if (res.status == 200) {
            // console.log(res.data);
            this.organList = res.data;
          }
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-date-editor--date .el-input__inner,
::v-deep .el-select .el-input__inner {
  height: 45px;
  border-radius: 11px;
}
</style>